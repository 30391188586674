import React from 'react';

import SEO from '../components/seo';
import { Base, Section } from '../components/common';

const NotFoundPage = () => (
  <Base>
    <SEO title="404 - Sidan finns inte!" />
    <Section className="expand-down">
      <h1>Hoppsan, du har hamnat fel</h1>
      <p className="text-center">Det verkar som du har kommit till en sida som inte finns. Berätta gärna för oss hur du kom hit.</p>
    </Section>
  </Base>
);

export default NotFoundPage;
